import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useBlogPostService from '../services/blogPostService';
import MDEditor from '@uiw/react-md-editor';
import Switch from 'react-bootstrap/Switch';

const BlogPostEdit = () => {
    const navigate = useNavigate();
    const { postId } = useParams();

    const { getBlogPostById, updateBlogPost } = useBlogPostService();
    const { user } = useAuth0();
    const [error, setError] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isHome, setIsHome] = useState(true);

    const [blogPost, setBlogPost] = useState([]);

    useEffect(() => {
        const fetchAndSetBlogViews = async () => {
            try {
                const post = await getBlogPostById(postId);
                setBlogPost(post);
                initIsHome(post.categoryNames)
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchAndSetBlogViews();
    }, []);

    const updateMd = (text) => {
        setBlogPost({ ...blogPost, body: text });
    }

    const userHasAdminRole = () => {
        return user?.['https://gluck.blog/roles']?.includes('BlogAdmin');
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setBlogPost({ ...blogPost, [name]: value });
    };

    const handleSetIsHome = (isHome) => {
        setIsHome(isHome);
        updateCategoryNames(isHome);
    }

    const initIsHome = (categories) => {
        var isHomeValue = categories.includes("home");
        setIsHome(isHomeValue);
    }

    const updateCategoryNames = (isHome) => {
        const newCategoryName = isHome
            ? 'home'
            : 'road';
        console.log(newCategoryName)
        setBlogPost({ ...blogPost, categoryNames: [newCategoryName] });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await updateBlogPost(postId, blogPost);

            if (response.ok) {
                console.log('Blog post created successfully!');
                setBlogPost({ Header: "", ImageUrl: "", Body: "" });
                navigate('/posts');
            } else {
                console.error('Blog post creation failed!');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='mainContainerSinglePost'>
            <Row>
                <Col xs={9} xl={9} className='postContainerSinglePost'>
                    <div className='user-form'>
                        <div className='heading'>
                            {isLoading && <p>Loading...</p>}
                            {Object.keys(error).length > 0 && <p>Error: {error}</p>}
                            <p>Edit Blog Post</p>
                            <Switch
                                checked={isHome}
                                onChange={() => handleSetIsHome(!isHome)}
                                label={isHome ? 'Home' : 'Road'}
                            />
                        </div>
                        {blogPost.Header != null > 0 &&
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label htmlFor="header" className="form-label">
                                            Header
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="header"
                                            name="header"
                                            value={blogPost.header}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 mt-3">
                                    <label htmlFor="imageUrl" className="form-label">Image URL</label>
                                    <input type="text"
                                        className="form-control"
                                        id="imageUrl"
                                        name="imageUrl"
                                        value={blogPost.imageUrl}
                                        onChange={handleInputChange} />
                                </div>
                                <div className="mb-12" data-color-mode="light">
                                    <label htmlFor="body" className="form-label">Body</label>
                                    <MDEditor
                                        value={blogPost.body}
                                        onChange={updateMd}
                                    />
                                </div>
                                <br />
                                <div className="mb-12 container" data-color-mode="light">
                                    <MDEditor.Markdown source={blogPost.body} />
                                </div>
                                {userHasAdminRole() &&
                                    <button type="submit" className="btn btn-primary submit-btn">Submit</button>
                                }
                            </form>
                        }
                    </div>
                </Col>
                <Col xs={3} xl={3}>
                    <div className="vertical-line"></div>
                </Col>
            </Row>
        </div>
    );
};

export default withAuthenticationRequired(BlogPostEdit, {
    onRedirecting: () => (
        <div>Redirecting you to the login page...</div>
    )
});